import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import checkout_45global from "/usr/src/app/middleware/checkout.global.ts";
import loader_45global from "/usr/src/app/middleware/loader.global.ts";
import locale_45route_45global from "/usr/src/app/middleware/localeRoute.global.ts";
import modal_45global from "/usr/src/app/middleware/modal.global.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  checkout_45global,
  loader_45global,
  locale_45route_45global,
  modal_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "about-us": () => import("/usr/src/app/middleware/about-us.ts"),
  "account-mobile": () => import("/usr/src/app/middleware/account-mobile.ts"),
  auth: () => import("/usr/src/app/middleware/auth.ts"),
  checkout: () => import("/usr/src/app/middleware/checkout.ts"),
  confirm: () => import("/usr/src/app/middleware/confirm.ts"),
  "create-order": () => import("/usr/src/app/middleware/create-order.ts"),
  declaration: () => import("/usr/src/app/middleware/declaration.ts"),
  guest: () => import("/usr/src/app/middleware/guest.ts"),
  marketplace: () => import("/usr/src/app/middleware/marketplace.ts"),
  "order-form-auth": () => import("/usr/src/app/middleware/order-form-auth.ts"),
  "payment-accept": () => import("/usr/src/app/middleware/payment-accept.ts"),
  payment: () => import("/usr/src/app/middleware/payment.ts"),
  quotes: () => import("/usr/src/app/middleware/quotes.ts"),
  tracking: () => import("/usr/src/app/middleware/tracking.ts")
}